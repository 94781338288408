import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from 'gatsby'
import { useDispatch, useSelector } from "react-redux";
import { BookingSlice } from "../../state/booking";
import MiniCart from "./MiniCart";
import Headerbox from "./DateFinder";
import OrderDetails from "./OrderDetails";


function CheckoutWidget() {

    // Use Booking Slice
    const dispatch = useDispatch();
    const ref = useRef();


    return (
        <>
            <div className="courses-details-area pb-100">
                <div className="courses-details-image">

                </div>
                <div className="container">
                    <h2>Review Your booking</h2>
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="courses-details-desc">
                                <div>
                                </div>
                                <div>
                                    <OrderDetails></OrderDetails>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <MiniCart></MiniCart>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CheckoutWidget;
