import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CheckoutWidget from "../components/Booking/CheckoutWidget"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const BookNow = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Retreat Booking"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Retreat Booking"
            />
            <GoogleReCaptchaProvider reCaptchaKey="6LemzdokAAAAAPRYfDfdFVKDLtLrwXwSyAQ9Iv6Y">
                <CheckoutWidget />
            </GoogleReCaptchaProvider>


            <Footer />
        </Layout>
    );
}

export default BookNow