import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useDispatch, useSelector } from "react-redux";

import course1 from '../../assets/images/courses/course1.jpg'

import Loadable from '@loadable/component'
const ModalVideo = Loadable(() => import('react-modal-video'))

// functional component
const MiniCart = () => {
    const [isOpen, setIsOpen] = React.useState(true);
    const amount = 0;
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const checkinDate = useSelector(state => {
        if (state.booking.checkin) {
            return new Intl.DateTimeFormat('en-US', options).format(state.booking.checkin)
        } else {
            return "NA"
        }
    });

    const checkoutDate = useSelector(state => {
        if (state.booking.checkout) {
            return new Intl.DateTimeFormat('en-US', options).format(state.booking.checkout)
        } else {
            return "NA"
        }
    });
    const adultGuest = useSelector(state => state.booking.guestAdult);
    const childGuest = useSelector(state => state.booking.guestChild);

    const openModal = () => {
        setIsOpen(!isOpen);
    }

    return (


        <div className="courses-details-info">
            <h3>Booking Details</h3>

            <ul className="info">
                <li className="price">
                    <div className="d-flex justify-content-between align-items-center">
                        <span><i className="flaticon-price-tag"></i> Total Amount</span>
                        {amount}
                    </div>
                </li>
                <li>
                    <div className="d-flex justify-content-between align-items-center">
                        <span><i className="flaticon-stopwatch"></i> Checkin</span>
                        {checkinDate}
                    </div>
                </li>
                <li>
                    <div className="d-flex justify-content-between align-items-center">
                        <span><i className="flaticon-stopwatch"></i> Checkout</span>
                        {checkoutDate}
                    </div>
                </li>
                <li>
                    <div className="d-flex justify-content-between align-items-center">
                        <span><i class='flaticon-teacher' ></i> Adult </span>
                        {adultGuest}
                    </div>
                </li>
                <li>
                    <div className="d-flex justify-content-between align-items-center">
                        <span><i class='flaticon-user' ></i> Child</span>
                        {childGuest}
                    </div>
                </li>
                <li>
                    <div className="d-flex justify-content-between align-items-center">
                        <span><i className="flaticon-house"></i> Total Rooms</span>
                        Lifetime
                    </div>
                </li>
            </ul>



        </div >
    )
}

export default MiniCart